<template>
  <div class="consumer-dashboard ecommerce-dashboard">
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info dashboard-calendar mb-6">
          <!-- Date Picker -->
          <date-range-picker
            :position="'left'"
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="
              (newFromDate) => {
                fromDate = newFromDate;
              }
            "
            @inputToDate="
              (newToDate) => {
                toDate = newToDate;
              }
            "
          ></date-range-picker>
          <!-- Update Dashboard -->
          <vs-button
            type="filled"
            color=""
            class="small-btn bg-third"
            @click="updateDashboard()"
          >
            {{ LabelConstant.buttonLabelUpdateDashboard }}
          </vs-button>
        </div>
      </div>
    </div>
    <h2 class="mb-4">Cart Identification Metrics</h2>
    <!-- Revenue Opportunity, Carts Abandoned, Average Cart Value -->
    <div class="vx-row">
      <!-- Revenue Opportunity -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-0">
        <vx-card class="h-100">
          <div class="budget-title card-title">
            <span class="material-icons text-second"> monetization_on </span>
            <h2 class="p-0">
              Revenue Opportunity
              <span class="text-secondary"
                ><feather-icon icon="HelpCircleIcon"></feather-icon
              ></span>
              <span class="up-down-percent" :class="textColorUpDown(revenueOpportunityStatChangePercent)"><feather-icon :icon="iconUpDown(revenueOpportunityStatChangePercent)"></feather-icon> {{ revenueOpportunityStatChangePercent }}</span>
            </h2>
          </div>
          <h3 class="text-second">
            {{ revenueOpportunityCurrentValue }}
          </h3>
          <apexchart
            type="bar"
            height="180"
            :options="revenueOppurtunityChart.chartOptions"
            :series="revenueOppurtunityChart.series"
          ></apexchart>
          <div class="currently-budget">
            <span class="previous-period border-top"
              ><div>
                Previous Period
                <vx-tooltip color="secondary" :text="revenueOpportunityPreviousPeriod">
                  <feather-icon icon="HelpCircleIcon"></feather-icon>
                </vx-tooltip>
              </div>
              <span class="prev-price">{{ revenueOpportunityPreviousValue }}</span>
            </span>
          </div>
        </vx-card>
      </div>
      <!-- Carts Abandoned -->
      <div class="vx-col xl:w-2/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-1">
        <vx-card>
          <div class="card-title budget-title">
            <h2>
              Carts Abandoned
              <span class="text-secondary"
                ><feather-icon icon="HelpCircleIcon"></feather-icon
              ></span>
              <span class="up-down-percent" :class="textColorUpDown(cartAbandonedStatChangePercent)"><feather-icon :icon="iconUpDown(cartAbandonedStatChangePercent)"></feather-icon> {{ cartAbandonedStatChangePercent }}</span>
            </h2>
          </div>
          <div class="vx-row align-items-center">
            <div
              class="vx-col max-40"
              id="v-step-11"
            >
              <h4 class="mb-5">{{ cartAbandonedCurrentValue }}</h4>
              <div class="currently-budget">
                <span class="previous-period"
                  ><div>
                    Previous Period
                    <vx-tooltip color="secondary" :text="cartAbandonedPreviousPeriod">
                      <feather-icon icon="HelpCircleIcon"></feather-icon>
                    </vx-tooltip>
                  </div>
                  <span class="prev-price">{{ cartAbandonedPreviousValue }}</span>
                </span>
              </div>
            </div>
            <div
              class="vx-col max-60"
              id="v-step-12"
            >
            <apexchart type="area" height="120" :options="cartAbandonedChart.chartOptions" :series="cartAbandonedChart.series"></apexchart>
            </div>
            <!-- Carts Started -->
            <div
              class="vx-col xl:w-1/3 lg:w-3/3 md:w-2/2 w-full lg-cus-6 min-card"
              id="v-step-12"
            >
              <vx-card class="carts-card">
                <div class="card-small-title">
                <h2>
                  Carts Started
                </h2>
                <span class="text-secondary"
                  ><feather-icon icon="HelpCircleIcon"></feather-icon
                ></span>
              <span class="up-down-percent" :class="textColorUpDown(cartStartedStatChangePercent)"><feather-icon :icon="iconUpDown(cartStartedStatChangePercent)"></feather-icon> {{ cartStartedStatChangePercent }}</span>
            </div>
              <h4 class="mb-5">{{ cartStartedCurrentValue }}</h4>
                <div class="currently-budget">
                  <span class="previous-period"
                    ><div>
                      Previous Period
                      <vx-tooltip color="secondary" :text="cartStartedPreviousPeriod">
                        <feather-icon icon="HelpCircleIcon"></feather-icon>
                      </vx-tooltip>
                    </div>
                    <span class="prev-price">{{ cartStartedPreviousValue }}</span>
                  </span>
                </div>
              </vx-card>
            </div>
            <!-- Carts Recovered -->
            <div
              class="vx-col xl:w-1/3 lg:w-3/3 md:w-2/2 w-full lg-cus-6 min-card"
              id="v-step-12"
            >
              <vx-card class="carts-card">
                <div class="card-small-title">
                <h2>
                  Carts Recovered
                </h2>
                <span class="text-secondary"
                  ><feather-icon icon="HelpCircleIcon"></feather-icon
                ></span>
                <span class="up-down-percent" :class="textColorUpDown(cartRecoveredStatChangePercent)"><feather-icon :icon="iconUpDown(cartRecoveredStatChangePercent)"></feather-icon> {{ cartRecoveredStatChangePercent }}</span>
            </div>
              <h4 class="mb-5">{{ cartRecoveredCurrentValue }}</h4>
                <div class="currently-budget">
                  <span class="previous-period"
                    ><div>
                      Previous Period
                      <vx-tooltip color="secondary" :text="cartRecoveredPreviousPeriod">
                        <feather-icon icon="HelpCircleIcon"></feather-icon>
                      </vx-tooltip>
                    </div>
                    <span class="prev-price">{{ cartRecoveredPreviousValue }}</span>
                  </span>
                </div>
              </vx-card>
            </div>
            <!-- Conversion Rate -->
            <div
              class="vx-col xl:w-1/3 lg:w-3/3 md:w-2/2 w-full lg-cus-6 min-card"
              id="v-step-12"
            >
              <vx-card class="carts-card">
                <div class="card-small-title">
                <h2>
                  Conversion Rate 
                </h2>
                <span class="text-secondary"
                  ><feather-icon icon="HelpCircleIcon"></feather-icon
                ></span>
                <span class="up-down-percent" :class="textColorUpDown(conversionRateStatChangePercent)"><feather-icon :icon="iconUpDown(conversionRateStatChangePercent)"></feather-icon> {{ conversionRateStatChangePercent }}</span>
            </div>
              <h4 class="mb-5">{{ conversionRateCurrentValue }}</h4>
                <div class="currently-budget">
                  <span class="previous-period"
                    ><div>
                      Previous Period
                      <vx-tooltip color="secondary" :text="cartAbandonedPreviousPeriod">
                        <feather-icon icon="HelpCircleIcon"></feather-icon>
                      </vx-tooltip>
                    </div>
                    <span class="prev-price">{{ conversionRatePreviousValue }}</span>
                  </span>
                </div>
              </vx-card>
            </div>
          </div>
        </vx-card>
      </div>
      <!-- Average Cart Value -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-2">
        <vx-card>
          <div class="card-title budget-title">
            <span class="material-icons text-first"> monetization_on </span>
            <h2>
              Average Cart Value
              <span class="text-secondary"
                ><feather-icon icon="HelpCircleIcon"></feather-icon
              ></span>
              <span class="up-down-percent" :class="textColorUpDown(averageCartStatChangePercent)"><feather-icon :icon="iconUpDown(averageCartStatChangePercent)"></feather-icon> {{ averageCartStatChangePercent }}</span>
            </h2>
          </div>
          <h3 class="text-first">
            {{ averageCartCurrentValue }}
          </h3>
          <apexchart
            type="bar"
            height="180"
            :options="averageCartChart.chartOptions"
            :series="averageCartChart.series"
          ></apexchart>
          <div class="currently-budget">
            <span class="previous-period border-top"
              ><div>
                Previous Period
                <vx-tooltip color="secondary" :text="averageCartPreviousPeriod">
                  <feather-icon icon="HelpCircleIcon"></feather-icon>
                </vx-tooltip>
              </div>
              <span class="prev-price">{{ averageCartPreviousValue }}</span>
            </span>
          </div>
        </vx-card>
      </div>
      <!-- Top Abandoned Carts -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-3">
        <vx-card class="main-list h-full">
          <div class="card-title justify-content-start">
            <h2 class="w-auto">Top Abandoned Carts <feather-icon icon="HelpCircleIcon"></feather-icon></h2>
            
            <router-link
              class="text-bold text-second"
              :to="{name:'ecommerce-search-carts'}"
              >View More</router-link
            >
          </div>
          <ul class="latest-list mb-0">
            <li v-show="topAbandondedCarts.length > 0" v-for="(cart, i) in topAbandondedCarts" :key="i">
              <a> {{ '$'+parseInt(cart.CartTotal) }} - {{ cart.BuyerName }} </a>
              <span class="date"> {{ cart.AbandonedDateTime }} </span>
              <a @click="consumerDetailPopup=true, getLeadDetail(cart.AbandonedCartId)"
                class="go-link text-third"
                ><feather-icon icon="EyeIcon"></feather-icon>
              </a>
            </li>
            <p v-if="topAbandondedCarts.length==0" class="text-sm">No carts for this date range.</p>
          </ul>
        </vx-card>
      </div>
      <!-- Recent Abandoned Carts -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-4">
          <vx-card class="main-list h-full">
            <div class="card-title justify-content-start">
              <h2 class="w-auto">Recent Abandoned Carts <feather-icon icon="HelpCircleIcon"></feather-icon></h2>
              
              <router-link
               class="text-bold text-second"
                :to="{name:'ecommerce-search-carts'}"
                >View More</router-link
              >
            </div>
            <ul class="latest-list mb-0">
              <li v-show="recentAbandondedCarts.length > 0" v-for="(cart, i) in recentAbandondedCarts" :key="i">
                <a> {{ '$'+parseInt(cart.CartTotal) }} - {{ cart.BuyerName }} </a>
                <span class="date"> {{ cart.AbandonedDateTime }} </span>
                <a @click="consumerDetailPopup=true, getLeadDetail(cart.AbandonedCartId)"
                  class="go-link text-third"
                  ><feather-icon icon="EyeIcon"></feather-icon>
                </a>
              </li>
              <p v-if="recentAbandondedCarts.length==0" class="text-sm">No carts for this date range.</p>
            </ul>
          </vx-card>
      </div>
      <!-- Cart Activity -->
      <div class="vx-col xl:w-2/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-5">
        <vx-card class="main-list h-full">
          <div class="card-title">
            <h2 class="w-auto">Cart Activity</h2>
          </div>
          <apexchart
            type="line"
            height="350"
            :options="cartActivityChart.chartOptions"
            :series="cartActivityChart.series"
          ></apexchart>
        </vx-card>
      </div>
    </div>
    <!-- consumer Detail pop up -->
    <search-cart-details-modal
        :consumerDetailPopup="consumerDetailPopup"
        @close="closepopup"
        :consumerLeadId="consumerLeadId"
        :consumerDetail="consumerDetail"
      ></search-cart-details-modal>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import CompanyDetailsModal from "../../components/modal/CompanyDetailsModal";
import SingleCard from "@/components/SingleCard";
import ConsumerLeadDetailModal from "@/components/modal/consumer/ConsumerLeadDetailModal";
import SearchCartDetailsModal from "../../components/modal/consumer/SearchCartDetailsModal.vue";
/* chart */
import VueApexCharts from "vue-apexcharts";

export default {
  title: "Dashboard",
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,
    HelpModal,
    CompanyDetailsModal,
    SingleCard,
    apexchart: VueApexCharts,
    ConsumerLeadDetailModal,
    SearchCartDetailsModal
  },
  data() {
    return {
      consumerDetailPopup: false,
      consumerLeadId: null,
      consumerDetail: null,
      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      // Revenue Opportunity
      revenueOpportunityCurrentValue: "",
      revenueOpportunityPreviousValue: "",
      revenueOpportunityStatChangePercent: "",
      revenueOpportunityPreviousPeriod: "",
      revenueOppurtunityChart: {
        series: [
          {
            name: "Revenue Opportunity",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
    toolbar: {
      show: false
    }
  },
          colors: ["#7ac546"],
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
          },     
          tooltip: {
            type: "datetime",
            shared: true,
            intersect: false,
            x: {
              show: true,
              format: 'M/d',
            },
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        },
      },

      //Carts Abandoned
      cartAbandonedCurrentValue: "",
      cartAbandonedPreviousValue: "",
      cartAbandonedStatChangePercent: "",
      cartAbandonedPreviousPeriod: "",
      cartAbandonedChart: {
        series: [
          {
            name: "Carts Abandoned",
            data: []
          },         
        ],
        chartOptions: {
          chart: {
    toolbar: {
      show: false
    }
  },
          colors: ['#ecb940'],
          stroke: {
            width: [1],
            curve: "smooth"
          },
          xaxis: {
            // type: "datetime",
            categories: [],
            labels: {
              show: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          tooltip: {
            type: "datetime",
            shared: true,
            intersect: false,
            x: {
              show: true,
              format: 'M/d',
            },
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        }
      },

      //Carts Started
      cartStartedCurrentValue: "",
      cartStartedPreviousValue: "",
      cartStartedStatChangePercent: "",
      cartStartedPreviousPeriod: "",

      //Carts Recovered
      cartRecoveredCurrentValue: "",
      cartRecoveredPreviousValue: "",
      cartRecoveredStatChangePercent: "",
      cartRecoveredPreviousPeriod: "",

      //Conversion Rate
      conversionRateCurrentValue: "",
      conversionRatePreviousValue: "",
      conversionRateStatChangePercent: "",
      conversionRatePreviousPeriod: "",

      //Average Cart Value
      averageCartCurrentValue: "",
      averageCartPreviousValue: "",
      averageCartStatChangePercent: "",
      averageCartPreviousPeriod: "",
      averageCartChart: {
        series: [
          {
            name: "Average Cart Value",
            data: [],
          },
        ],
        chartOptions: {
      chart: {
toolbar: {
  show: false
}
},
      colors: ["#044d80"],
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
      },     
      tooltip: {
        type: "datetime",
        shared: true,
        intersect: false,
        x: {
          show: true,
          format: 'M/d',
        },
        y: {
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
      },
    },
      },

      topAbandondedCarts: [],
      recentAbandondedCarts: [],
      cartActivityChart: {
        series: [{
                name: "Abandoned Carts",
                type: "line",
                data: []
            },
            {
                name: "Unique Identified Visitors",
                type: "line",
                data: []
            },
            {
                name: "Items Viewed",
                type: "line",
                data: []
            }
        ],
        chartOptions: {
            colors: [
                '#044d80',
                '#ecb940',
                '#7ac546'
            ],
            chart: {
    toolbar: {
      show: false
    }
  },
            stroke: {
                width: [1, 1, 1],
                curve: "smooth"
            },
            plotOptions: {
                bar: {
                    columnWidth: "30%",
                    endingShape: "rounded"
                }
            },
            fill: {
                type: "solid",
                opacity: [1, 1, 1],
                gradient: {
                    type: "vertical",
                    shadeIntensity: 0,
                    opacityFrom: 1,
                    opacityTo: 0.2,
                    stops: [0, 90, 100]
                }
            },
            markers: {
                size: 0
            },
            xaxis: {
                type: "datetime",
                categories: [],
                labels: {
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    format: "M/d"
                }
            },
            legend: {
                position: "bottom",
                fontSize: "12px",
                fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
                itemMargin: {
                    horizontal: 20,
                    vertical: 10
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                markers: {
                    width: 12,
                    height: 12,
                    radius: 2
                }
            },
            grid: {
                show: true,
                borderColor: "#f2f2f2",
                strokeDashArray: 3,
                position: "back",
                xaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            yaxis: [
                {
                    seriesName: 'Abandoned Carts',
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: '#044d80',
                    },
                    labels: {
                        formatter: function (val) {
                            return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                        style: {
                            color: '#044d80',
                        }
                    },
                    title: {
                        text: 'Abandoned Carts',
                        style: {
                            color: '#044d80',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    seriesName: 'Unique Identified Visitors',
                    opposite: true,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: '#ecb940'
                    },
                    labels: {
                        formatter: function (val) {
                            return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                        style: {
                            color: '#ecb940'
                        }
                    },
                    title: {
                        text: 'Unique Identified Visitors',
                        style: {
                            color: '#ecb940'
                        }
                    }
                },
                {
                    seriesName: 'Items Viewed',
                    opposite: true,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: '#7ac546'
                    },
                    labels: {
                        formatter: function (val) {
                            return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                        style: {
                            color: '#7ac546'
                        }
                    },
                    title: {
                        text: 'Items Viewed',
                        style: {
                            color: '#7ac546'
                        }
                    }
                }
            ],
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    format: "M/d"
                },
                y:{
                    formatter: function (val) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                }
            }
        }
      },
    }
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.getRevenueOpportunityWidget();
    this.getAverageCartValueWidget();
    this.getCartsAbandonedWidget();
    this.getCartsStartedWidget();
    this.getCartsRecoveredWidget();
    this.getConversionRateWidget();
    this.getTopAbandondedCartsViewModel();
    this.getRecentAbandondedCartsViewModel();
    this.getCartActivityGraph();
  },
  methods: {
    iconUpDown(value){
      if(value.substring(0,1) == '+'){
          return 'ArrowUpIcon';
      } else if(value.substring(0,1) == '-'){
        return 'ArrowDownIcon';
      } else{
        return '';  
      }
    },
    textColorUpDown(value) {
      if(value){
        if(value.substring(0,1) == '+'){
          return 'text-second';
        } else if(value.substring(0,1) == '-'){
          return 'text-danger';
        } else{
          return 'text-gray';  
        }    
      } 
    },
    updateDashboard(){
      this.getRevenueOpportunityWidget();
      this.getAverageCartValueWidget();
      this.getCartsAbandonedWidget();
      this.getCartsStartedWidget();
      this.getCartsRecoveredWidget();
      this.getConversionRateWidget();
      this.getTopAbandondedCartsViewModel();
      this.getRecentAbandondedCartsViewModel();
      this.getCartActivityGraph();
    },
    getRevenueOpportunityWidget(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetRevenueOpportunityWidget",payload).then(response => {
        let data = response.data;
        this.revenueOpportunityCurrentValue = data.CurrentValue;
        this.revenueOpportunityPreviousValue = data.PreviousValue;
        this.revenueOpportunityStatChangePercent = data.StatChangePercent;
        this.revenueOpportunityPreviousPeriod = data.PreviousPeriod;
        this.revenueOppurtunityChart.series[0].data = [];
          let x = [];
          if(data.DataPoints.length){
            data.DataPoints.forEach(data => {
              x.push(data.Key);
              this.revenueOppurtunityChart.series[0].data.push(data.Value);
            });
            this.revenueOppurtunityChart.chartOptions = {
              ...this.revenueOppurtunityChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                }
              }
            };
          }
          
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getAverageCartValueWidget(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetAverageCartValueWidget",payload).then(response => {
        let data = response.data;
        this.averageCartCurrentValue = data.CurrentValue;
        this.averageCartPreviousValue = data.PreviousValue;
        this.averageCartStatChangePercent = data.StatChangePercent;
        this.averageCartPreviousPeriod = data.PreviousPeriod;
        this.averageCartChart.series[0].data = [];
          let x = [];
    //       let chartData = [
    //     {
    //         "Key": "4/21/2023",
    //         "Value": 63
    //     },
    //     {
    //         "Key": "4/22/2023",
    //         "Value": 0
    //     },
    //     {
    //         "Key": "4/23/2023",
    //         "Value": 0
    //     },
    //     {
    //         "Key": "4/24/2023",
    //         "Value": 0
    //     },
    //     {
    //         "Key": "4/25/2023",
    //         "Value": 0
    //     }
    // ]
          if(data.DataPoints.length){
            data.DataPoints.forEach(data => {
              x.push(data.Key);
              this.averageCartChart.series[0].data.push(data.Value);
            });
            this.averageCartChart.chartOptions = {
              ...this.averageCartChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                }
              }
            };
          }
          
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getCartsAbandonedWidget(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetCartsAbandonedWidget",payload).then(response => {
        let data = response.data;
        this.cartAbandonedCurrentValue = data.CurrentValue;
        this.cartAbandonedPreviousValue = data.PreviousValue;
        this.cartAbandonedStatChangePercent = data.StatChangePercent;
        this.cartAbandonedPreviousPeriod = data.PreviousPeriod;

        this.cartAbandonedChart.series[0].data = [];
        let x = [];
          if(data.DataPoints.length){
            data.DataPoints.forEach(data => {
              x.push(data.Key);
              this.cartAbandonedChart.series[0].data.push(data.Value);
            });
            this.cartAbandonedChart.chartOptions = {
              ...this.cartAbandonedChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                }
              }
            };
          }
          
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getCartsStartedWidget(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetCartsStartedWidget",payload).then(response => {
        let data = response.data;
        this.cartStartedCurrentValue = data.CurrentValue;
        this.cartStartedPreviousValue = data.PreviousValue;
        this.cartStartedStatChangePercent = data.StatChangePercent;
        this.cartStartedPreviousPeriod = data.PreviousPeriod;
          
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getCartsRecoveredWidget(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetCartsRecoveredWidget",payload).then(response => {
        let data = response.data;
        this.cartRecoveredCurrentValue = data.CurrentValue;
        this.cartRecoveredPreviousValue = data.PreviousValue;
        this.cartRecoveredStatChangePercent = data.StatChangePercent;
        this.cartRecoveredPreviousPeriod = data.PreviousPeriod;
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getConversionRateWidget(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetConversionRateWidget",payload).then(response => {
        let data = response.data;
        this.conversionRateCurrentValue = data.CurrentValue;
        this.conversionRatePreviousValue = data.PreviousValue;
        this.conversionRateStatChangePercent = data.StatChangePercent;
        this.conversionRatePreviousPeriod = data.PreviousPeriod;
          
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getTopAbandondedCartsViewModel(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetTopAbandondedCartsViewModel",payload).then(response => {
        let data = response.data;
        this.topAbandondedCarts = data.AbandonedCarts;      
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getRecentAbandondedCartsViewModel(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetRecentAbandondedCartsViewModel",payload).then(response => {
        let data = response.data;
        this.recentAbandondedCarts = data.AbandonedCarts;
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getCartActivityGraph(){
      let payload = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios.post("/ws/EcommerceDashboard/GetCartActivityGraph",payload).then(response => {
        let data = response.data;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          let ThirdGroup = [];

          data.AbandonedCarts.forEach(data => {
            x.push(data.Key);
            FirstGroup.push(data.Value);
          });
          data.UniqueVisitors.forEach(data => {
            SecondGroup.push(data.Value);
          });
          data.ItemsViewed.forEach(data => {
            ThirdGroup.push(data.Value);
          });
          this.cartActivityChart.series[0].data = FirstGroup;
          this.cartActivityChart.series[1].data = SecondGroup;
          this.cartActivityChart.series[2].data = ThirdGroup;
          this.cartActivityChart.chartOptions = {
              ...this.cartActivityChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                  // categories: ["2020-03-01","2020-03-02","2020-03-03","2020-03-04","2020-03-05","2020-03-06","2020-03-07"]
                  // categories: ["03-01-2020","03-02-2020","03-03-2020","03-04-2020","03-05-2020","03-06-2020","03-07-2020"]
                }
              }
            };
          
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    // open lead detail
    getLeadDetail(id){
      this.consumerLeadId=id
      this.consumerDetail={}
      this.$vs.loading();
      this.axios.get("/ws/AbandonedCarts/GetAbandonedCartDetail?abandonedCartId="+id)
        .then((response) => {
          let data = response.data;
          this.$vs.loading.close();
          this.consumerDetail=data
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
          });
          this.showError(e);
        });
    },
    // close lead detail pop up
    closepopup(){
      this.consumerDetailPopup=false
    },
  },
};
</script>
